#Demo {
  height: 100vh;
  width: 100vw;
  background: black;
  display: flex;

  .vid-cnr {
    margin: auto;
    height: 100%;
    width: 100%;
    display: flex;
  }

  video {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    // animation: video-in 0.5s ease both;
  }
}

@keyframes video-in {
  from { opacity: 0 }
  to { opacity: 1 }
}