@import "./vars.scss";

#ADAToggle {
  font-size: 3rem;
  margin-left: 0.75em;
  border: 0.12rem solid white;
  width: 1.5em; height: 1.5em;
  border-radius: 100%;
  display: inline-flex;
  vertical-align: bottom;
  box-sizing: content-box;
  -webkit-tap-highlight-color: initial;

  svg {
    width: 50%; height: auto; margin: auto;
  }
}

@media screen and (max-height: 1280px) {
  #ADAToggle {
    border: 1px solid white;
  }
}