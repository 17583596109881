@import './vars.scss';

.App {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  background: black;
  overflow: hidden;
  position: relative;
}

.blackout {
  position: fixed;
  top: 0; right: 0; left: 0; bottom: 0;
  background: black;
}

#BSToggles {
  font-size: 1rem;
  position: absolute;
  display: block;
  bottom: calc(1em - 2px);
  left: 3em;
  transform: translateY(-50%);
  z-index: 1;
  align-items: flex-end;
}

.video-close {
  position: absolute;
  font-size: 1rem;
  top: 1.5em; right: 1.5em;
  width: 3em; height: 3em;
  border-radius: 50%;
  padding: 1.5em;
  box-sizing: content-box;
  svg {
    width: 100%; height: 100%;
  }
}

@media (max-width: $media-public-max-width) {
  .video-close {
    top: 1rem; right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.5rem;
  }
}