@import './vars.scss';

#FeatureListen {
  .c1 {
    .g1 {
      position: absolute;
      left: 3em;
      top: 8em;
      .title { font-size: 5em; }
      .description { max-width: 16em; }
    }
  }

  .c2 {
    .g1 {
      position: absolute;
      top: 12.5vh;
      left: 6em;
      .title {
        font-size: 4em;
      }
      .tips > * {
        position: absolute;
      }
    }
  }

  .c3 {
    .g1 {
      position: absolute;
      text-align: right;
      width: 60vw;
      right: 6em;
      top: 10vh;
      .title {
        font-size: 4em;
      }
      .description {
        max-width: 15em;
        margin-left: auto;
      }
    }
  }
}

@media
  (min-aspect-ratio: $media-ipad-min-aspect) and (min-width: $media-ipad-min-width) and (max-width: $media-ipad-max-width) {
  #FeatureListen {
    .c2 {
      .g1 {
        left: 3em;
      }
    }
    .c3 {
      .g1 {
        right: 3em;
      }
    }
  }
}

@media (max-height: #{$bs-xd-height - 1px}) {
  #FeatureListen {
    .c2 {
      .g1 {
        top: 10em;
      }
    }
    .c3 {
      .g1 {
        top: 9em;
      }
    }
  }
}