@import "./fonts.scss";
@import "./vars.scss";

* { box-sizing: border-box; user-select: inherit; -webkit-tap-highlight-color: inherit; }

html, body, #root {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
}
html { font-size: 1.5vw; }
body {
  margin: 0; overflow: hidden;
  background: black;
  &:before, &:after {
    content: 'beep boop';
    position: absolute;
    top: 0; left: 0;
    z-index: -1;
    color: rgba(0,0,0,0);
    font-family: 'SFProDisplay';
    font-weight: 300;
  }
  &:before {
    font-family: 'SFProDisplay';
  }
  &:after {
    font-family: 'Ray-Ban Sans';
  }
}
#root {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}


@media (max-width: $media-public-max-width) {
  html { font-size: 1rem!important; }
}