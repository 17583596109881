@import './vars.scss';

#Attract {
  background-size: 100%;
  background-position: center;
  background: black;

  &::before {
    content: '...';
    font-family: 'SFProDisplay';
    opacity: 1;
    position: absolute;
    bottom: 0;
  }

  .black { z-index: 0; }

  main {
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    display: grid;
    flex-flow: column;
    grid-template-rows: auto 1fr auto;
    z-index: 1;
    padding: 5rem 0 15rem;
  }

  .bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .logo-cnr {
    position: relative;
    margin: 0 auto 0em;
    
    svg {
      width: 21.25rem;
      height: auto;
    }
  }

  .content {
    position: relative;
    margin: auto auto 0;
    font-family: 'Ray-Ban Sans';
    color: white;
    text-align: center;
    font-size: 1rem;
    grid-row: 3;

    .rbs-logo {
      display: block;
      font-size: 9.5rem;
      width: 3.5em;
      height: auto;
      margin: 0 auto 3rem;
    }

    .social {
      max-width: 20em;
      margin-bottom: 7.5rem;
    }

    .cta {
      font-size: 4em;
      font-weight: 400;
      margin: 0;
      animation: cta-bounce 2s ease infinite;
    }
  }


  .links-cnr {
    margin: auto;
   
    a {
      font-size: 2.5rem;
      font-family: 'Ray-Ban Sans';
      font-weight: 700;
      display: block;
      width: 8em;
      text-align: center;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0.66em 0;
      background: rgba(0,0,0,0.2);
      border-radius: 0.5em;
      margin-top: 1em;
      border: 1px solid white;

      &:first-of-type { margin-top: 0; }
    }
  }
}

.App[data-dist="ce"] {
  #Attract {
    main {
      padding: 5rem 0 15rem;
    };
    .branding {
      position: relative;
    }
    .qr-cnr {
      font-size: 1rem;
      position: fixed;
      overflow: hidden;
      border-radius: 1.5rem;
      display: inline-block;
      width: 10rem;
      height: 10rem;
      bottom: calc(3em);
      right: 3em;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      .cta {
        font-size: 3.66em;
        animation: ce-cta-bounce 2s ease infinite;
      }
      .social {
        margin-bottom: 7.5rem;
      }
    }
  }
}

.App[data-dist="meta_hs"] {
  #Attract {
    .logo-cnr { visibility: hidden; }
    .branding { visibility: hidden; }
    .cta { visibility: hidden; }
  }
}

@keyframes cta-pulse {
  0% { opacity: 1 }
  50% { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes cta-bounce {
  0%   { transform: scale(1) }
  10%  { transform: scale(0.95, 0.9) }
  30%  { transform: scale(1.1, 1.1) }
  50%  { transform: scale(1, 0.95) }
  56%  { transform: scale(1.05, 1.025) }
  70%  { transform: scale(1) }
  100% { transform: scale(1) }
}

@keyframes ce-cta-bounce {
  0%   { transform: scale(1) }
  10%  { transform: scale(0.95, 0.9) }
  30%  { transform: scale(1.05, 1.05) }
  50%  { transform: scale(1, 0.95) }
  56%  { transform: scale(1.05, 1.025) }
  70%  { transform: scale(1) }
  100% { transform: scale(1) }
}

@media (width: 800px) and (height: 1280px) {
  #Attract {
    main {
      padding: 5rem 0 12.5rem;
    }
    .logo-cnr {
      svg {
        width: 344px;
      }
    }
  }
}

@media (max-width: $media-public-max-width) {
  #Attract {
    .logo-cnr {
      margin: 2em auto 0em;
      svg {
        width: 200px;
      }
    }
    .content {
      .title {
        font-size: 15vw;
      }
      .cta {
        font-size: 2em;
      }
    }
  }
}