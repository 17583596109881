@import 'vars.scss';

#FeatureSpecs {
  .c1 {
    .g1 {
      position: absolute;
      top: 15vh;
      right: 6em;
      text-align: right;

      .title {
        font-size: 6em;
        margin-bottom: 0.66em;
      }

      .description {
        width: 15em;
        margin: 0;
        position: absolute;
        right: 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 2.25em;

        li {
          margin-bottom: 0.5em;
        }
      }
    }
  }

  .c2 {
    .g1, .g2 {
      position: absolute;
      .title {
        font-size: 4em;
        margin-bottom: 0.66em;
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 2em;
        font-weight: 300;
        line-height: 1.33em;
      }
      li {
        position: relative;
        max-width: 24em;
        margin-bottom: 0.5em;
      }
    }

    .g1 {
      top: 10vh;
      left: 6em;
      
      ul { margin-left: 0.5em; }
    }   
    .g2 {
      top: 50vh;
      right: 6em;
      text-align: right;
      
      ul { margin-right: 0.5em; }

      li {
        &:before {
          position: absolute;
          left: auto;
          right: -1em;
        }
      }
    }   
  }
}

.App[data-lang=fr], .App[data-lang=fr-CA] {
  #FeatureSpecs {
    .c2 {
      .g1 {
        li {
          &:nth-of-type(2) {
            max-width: 20em;
          }
        }
      }
    }
  }
}

@media (max-height: #{$bs-xd-height - 1px}) {
  #FeatureSpecs {
    .c1 {
      .g1 {
        top: 10em;
      }
    }
    .c2 {
      .g1 {
        top: 8em;
      }
    }
  }
}

@media
  (min-aspect-ratio: $media-ipad-min-aspect) and (min-width: $media-ipad-min-width) and (max-width: $media-ipad-max-width) {
  #FeatureSpecs {
    .c1 {
      .g1 {
        right: 3em;
      }
    }
    .c2 {
      ul { margin: auto!important; }
      .g1 {
        left: 8rem;
      }
      .g2 {
        top: auto!important;
        bottom: 12rem!important;
        right: 4rem!important;
        text-align: right!important;
        height: auto!important;
      }
    }
  }
}

@media (max-width: $media-public-max-width) {
  #FeatureSpecs {
    .c1 {
      .description {
        position: relative;
        left: 0;
      }
    }
    .c2 {
      ul { margin: auto!important; }
      .g2 {
        top: auto!important;
        bottom: 8rem!important;
        right: 1rem!important;
        text-align: right!important;
        height: auto!important;
      }
    }
  }
}