@import './vars.scss';

$loader-size: 30vw;
$loader-size-mobile: 12rem;
$max-scale: 1.2;
$cnr-size: calc(#{$loader-size} * #{$max-scale});

#LoaderOverlay {
  position: fixed;
  top: 50%; left: 50%;
  width: $cnr-size; height: $cnr-size;
  display: flex;
  transform: translate(-50%, -50%);
  animation: loader-enter 1s ease both;
  animation-delay: 0.5s;
  overflow: visible;

  svg {
    width: $loader-size;
    height: auto;
    display: block;
    margin: auto;
    overflow: visible;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%; left: 50%;

    .radial {
      path {
        transform-origin: 484.93px 322.8px;
        animation: loader-radial 1s ease infinite both;
        @for $i from 1 through 12 {
          &:nth-of-type(#{$i}) {
            animation-delay: #{$i * 0.2/12}s
          }
        }
      }
    }
  }
}

@keyframes loader-radial {
  0% { opacity: 0; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale($max-scale); }
}

@keyframes loader-enter {
  from { opacity: 0 }
  to { opacity: 1 }
}

@media (max-width: $media-public-max-width) {
  #LoaderOverlay {
    svg {
      width: $loader-size-mobile;
    }
  } 
}