@import './vars.scss';

#Features {
  height: 100%; width: 100%;
  background-size: 100%;
  background-position: center;
  color: white;
  position: relative;
  user-select: none;

  .transitioner {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: black;
  }

  .content-overlay {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    font-family: 'SFProDisplay';
    font-size: 1rem;
    .title { margin: 0; font-weight: 600; }
    h1.title { font-size: 4em; margin: 0; font-weight: 600; }
    h2.title { font-size: 3em; margin: 0; font-weight: 600; }
    .description { font-size: 2.5em; font-weight: 300; }
  }

  &.changing-language {
    .content-overlay {
      opacity: 0!important;
    }
  }

  .bubble {
    position: absolute;
    img { width: 100%; height: auto; }
  }

  .bg-layer {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tag {
    position: absolute;
    font-family: 'SFProDisplay';
    right: 1em;
    top: 7em;
    font-size: 3em;
    font-weight: 600;
    animation: tag-in 0.25s ease both 2s;
  }
}

.sequence-controls {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes tag-in {
  from { opacity: 0; transform: translateY(-10%); }
  to { opacity: 1; transform: translateY(0); }
}

@media
  (min-aspect-ratio: $media-ipad-min-aspect) and (min-width: $media-ipad-min-width) and (max-width: $media-ipad-max-width) {
  #Features {
    .content-overlay {
      > div {
        position: absolute;
        top: 2em!important;
      }
    }
  }
}

@media (max-width: $media-public-max-width) {
  #Features {
    .tag {
      font-size: 1em;
    }
    .content-overlay {
      > div {
        position: absolute;
        top: 2em!important;
        left: 1em!important;
        right: auto!important;
        text-align: left!important;
      }
      h1.title {
        font-size: 1.5em!important;
      }
      h2.title {
        font-size: 1.5em!important;
      }
      .description {
        font-size: 1em!important;
        margin-left: 0!important;
      }
      ul {
        font-size: 1em!important;
      }
    }
  }
}