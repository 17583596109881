#VideoLayer {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;

  &.no-bs {
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    &.lifestyle {
      video {
        object-fit: contain;
      }
    }
  }
}

.App[data-dist="soho"] {
  #VideoLayer {
    &.lifestyle {
      video {
        object-fit: cover;
        object-position: center bottom;
      }
    }
  }
}