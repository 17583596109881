@import './vars.scss';

#FeaturesHome {
  position: absolute;
  top: 0; left: 0;
  bottom: 0; right: 0;

  .content {
    position: absolute;
    left: 3em;
    width: calc(100% - 6em);

    .title {
      font-size: 6em;
      font-family: 'Ray-Ban Sans';
    }

    .description {
      font-family: 'SFProDisplay';
      font-size: 1.75em;
      max-width: 13em;
      line-height: 1.2em;
    }
  }
}

@media
  (min-aspect-ratio: $media-ipad-min-aspect) and (min-width: $media-ipad-min-width) and (max-width: $media-ipad-max-width) {
  #FeaturesHome {
    .content {
      bottom: auto!important;
      .title {
        font-size: 5em;
      }
    }
  }
}

@media (max-width: $media-public-max-width) {
  #FeaturesHome {
    .content {
      top: 0;
      left: 1em;
      width: 100%;

      .title {
        font-size: 2.5em;
      }

      .description {
        font-size: 1em;
        max-width: 80%;
      }
    }
  }
}

@media (max-width: $media-phone-max-width) {
  #FeaturesHome {
    .content {
      .title {
        font-size: 1.5em;
      }
    }
  }
}